// Bootstrap Variables

// Colors
$body-bg: #F9FCFF;
$body-color: #000000;
$text-muted: #A3A3A3;
$light: #ECEDF9;
$dark: #000000;
$gray: #4A4A4A;
$primary: #2A305D;
$link-hover-color: darken($primary, 15%);
$secondary: #283747;
$success: #0AB237;
$info: #45B8AC; 
$danger: #F11C0E;
$warning: #AF650E;
$hover-background: #000;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$border-color: #e6e6e7;
$hr-border-color: $border-color;
$custom-control-indicator-border-color: $border-color;
$card-border-color: #fff;
$input-border-color: $border-color;
$dropdown-link-hover-bg: #f2f2f2;
$input-color: $body-color;
$pagination-border-color: $border-color;
$input-group-addon-color: #888888;
$input-group-addon-bg: #f8fbff;
$input-placeholder-color: #A3A3A3;

// Font
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;
$font-family-poppins: 'Inter', sans-serif;
$font-awesome: 'Font Awesome 6 Free';
$font-family-base: $font-family-poppins;
$font-weight-bold: 500;
$btn-font-weight: 600;
$btn-font-family: $font-family-base;
$input-btn-font-size-lg: 16px;
$input-btn-font-size: 14px;
$input-font-size: 14px;
$h1-font-size: 64px;
$h2-font-size: 48px;
$h3-font-size: 32px;
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 20px;
$dropdown-font-size: 14px;

// Padding Margins
$dropdown-item-padding-x: 0.8rem;
// $input-btn-padding-y-lg: 0.594rem;
// $input-btn-padding-x-lg: 1rem;
// $input-btn-padding-y-sm: 0.375rem;
// $input-btn-padding-x-sm: 0.705rem;
// $input-btn-padding-y: .563rem;
// $input-btn-padding-x: 0.713rem;
// $input-padding-x: 1rem;
// $input-padding-y: 0.6878rem;
$navbar-nav-link-padding-x: 10px;
$navbar-padding-y: 12.5px;
$navbar-padding-x: 20px;
$hr-margin-y: 15px;
$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;
$label-margin-bottom: .5rem;
$form-group-margin-bottom: 1.25rem;

// Border Radius
$border-radius: 8px;
$dropdown-border-radius: 5px;
$btn-border-radius-lg: 100px;
$btn-border-radius-sm: 100px;
$btn-border-radius: 100px;
$border-radius-sm: 6px;
$card-border-radius: $border-radius-sm;
$input-group-btn-border-radius: $border-radius;

// Other Bootstrap Variables
$enable-negative-margins: true;
$link-hover-decoration: none;
$modal-md: 520px;
$modal-content-border-radius: 10px;
$input-btn-focus-box-shadow: none;
$custom-select-focus-box-shadow: none;
$border-1: 1px solid $border-color;
$hr-opacity: 1;
$spacer: 20px;
$spacers: (
  0: 0,
  1: 5px,
  2: 10px,
  4: 24px,
  5: 48px,
  s1: 1px,
  s2: 2px,
  s3: 3px,
  s4: 4px,
  3: $spacer - 5,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  16: 16px,
  12: 12px,
  14: 14px,
  20: 20px,
  22: 22px,
  24: 24px,
  25: 25px,
  28: 28px,
  30: 30px,
  32: 32px,
  35: 35px,
  36: 36px,
  40: 40px,
  45: 45px,
  50: 50px,
  56: 56px,
  58: 58px,
  64: 64px,
  65: 65px,
  79: 79px,
  80: 80px,
  95: 95px,
  100: 100px,
  120: 120px,
);

// Responsive Font Size Variables
$enable-responsive-font-sizes: true;
$rfs-base-font-size: 1rem;