@import './sharedStyles/variables.scss';

.text-align-center{
  text-align: center !important;
}

@import "~bootstrap/scss/bootstrap";

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #F9FCFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #F5F5F5;
  --bs-gray-200: #E0E0E0;
  --bs-gray-300: #C2C2C2;
  --bs-gray-400: #A3A3A3;
  --bs-gray-500: #808080;
  --bs-gray-600: #555555;
  --bs-gray-700: #4A4A4A;
  --bs-gray-800: #2E2E2E;
  --bs-gray-900: #141414;
  --bs-primary: #2a305d;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #000000;
  --bs-primary-rgb: 42, 48, 93;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-modal-bg: #fff !important;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 249, 252, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-font-inter: 'Inter', sans-serif;
  --bs-body-font-family: var(--bs-font-inter);
  --bs-body-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000000;
  --bs-body-bg: #f9fcff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e6e6e7;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #2a305d;
  --bs-link-hover-color: #555A7E;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.h1,h1{
  line-height: 1.5;
  font-weight: 700;
}

.h2,h2{
  line-height: 1.5;
  font-weight: 700;
}

.h3,h3{
  line-height: 1.5;
  font-weight: 600;
}

.h4,h4{
  line-height: 1.5;
  font-weight: 600;
}

.h5,h5{
  line-height: 28px;
  font-weight: 500;
}

.h6,h6{
  line-height: 1.5;
  font-weight: 600;
}


.profileImage{
  max-width: 200px;
  max-height: 200px;
  min-width: 50px;
  min-height: 50px;
}

.label-profileImage{
  cursor: pointer;
  margin: auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  position: relative;
  padding: 2px;
  &:hover{
    &:before{
      font-size: 20px;
      background: #000000aa;
      font-weight: 800;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 0;
      left: 0px;
      color: #fff;
      font-size: 40px;
      width: 200px;
      height: 200px;
      align-items: center;
      display: flex;
      justify-content: center;
      content: "\f0ee";
    }
  }
}

.btn{
  font-size: 14px;
  line-height: 21px;
  padding: 11px 15px;
  border-radius: 50rem;
  min-width: 110px;
}

.btn-lg{
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 13px 16px;
}

.btn-sm{
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 5px 15px;
}

.text-gray-100{
  color: var(--bs-gray-100);
}

.text-gray-200{
  color: var(--bs-gray-200);
}

.text-gray-300{
  color: var(--bs-gray-300);
}

.text-gray-400{
  color: var(--bs-gray-400);
}

.text-gray-500{
  color: var(--bs-gray-500);
}

.text-gray-600{
  color: var(--bs-gray-600);
}

.text-gray-700{
  color: var(--bs-gray-700);
}

.text-gray-800{
  color: var(--bs-gray-800);
}

.text-gray-900{
  color: var(--bs-gray-900);
}

.bg-gray-100{
  background-color: var(--bs-gray-100);
}

.bg-gray-200{
  background-color: var(--bs-gray-200);
}

.bg-gray-300{
  background-color: var(--bs-gray-300);
}

.bg-gray-400{
  background-color: var(--bs-gray-400);
}

.bg-gray-500{
  background-color: var(--bs-gray-500);
}

.bg-gray-600{
  background-color: var(--bs-gray-600);
}

.bg-gray-700{
  background-color: var(--bs-gray-700);
}

.bg-gray-800{
  background-color: var(--bs-gray-800);
}

.bg-gray-900{
  background-color: var(--bs-gray-900);
}

label{
  color: var(--bs-gray-700);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.form-control{
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  background: #fff;
}

.font-sm{
  font-size: 12px;
}

.font-14{
  font-size: 14px;
}

.font-20{
  font-size: 20px;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  &-track {
    background: transparent;
  }
  &-thumb {
    background: transparent;
    background-color: $primary;
    border-radius: 3px;
  }
}

.w-400{
  width: 400px;
}

.offcanvas{
  width: 300px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.min-vh-top-sidebar{
  min-height: calc(100vh - 120px);
}

.min-vh-quiz{
  min-height: calc(100vh - 227px);
}

.swal2-icon{
  margin: 0 auto !important;
}

.page-max-width{
  max-width: 1300px;
  margin: 0 auto;
}

.swal2-show{
  border: 2px solid $primary !important;
}

table{
  @extend .table, .table-bordered;
}

.min-w-auto{
  min-width: auto;
}

.mw-90p{
  max-width: 90%;
}

.w-380{
  max-width: 380px;
}

.mw-96p{
  max-width: 95%;
}

.mw-100p{
  max-width: 100%;
}

.mw-250{
  max-width: 250px;
}

.bookmark-image{
  width: 25px;
}

.form-check {
  padding-left: 2em !important;
  .form-check-input{
    width: 1.250em;
    height: 1.250em;
    margin-top: 0.13em;
    margin-left: -2em;
  }
}

sub,sup{
  font-size: 12px !important;
  font-family: Helvetica, Arial, sans-serif !important;
}

.report table th{
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 400;
}

.opacity-5{
  opacity: 0.5;
}

.btn:focus{
  box-shadow: none !important;
}

.form-check{
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

@media(max-width: 415px){
  .navbar-mobile{
    padding: 8px 10px 10px 10px !important;
    .navbar-brand{
      margin-right: 0 !important;
      img{
        width: 190px;
      }
    }
  }
}

.staticData{
  ul{
    margin-bottom: 0;
  }
}


.staticData > div > div{
  display: flex;
  align-items: center;
  border-bottom: $border-1;
  padding: 12px 0;
  & > button{
    @extend .btn, .btn-outline-primary, .pe-none, .flex-shrink-0
  }
}

@media(max-width: 575.98px){
  .staticData > div > div{
    flex-direction: column;
    justify-content: center;
    text-align: center;
    & > button{
      margin-bottom: 10px;
    }
  }
}

@media(min-width: 576px){
  .staticData > div > div {
    & > button{
      margin-right: 15px;
    }
  }
}

.btn-top-bar{
  @extend .btn, .btn-outline-primary, .min-w-auto, .d-inline-flex, .align-items-center, .ms-8;
}

.btn-outline-primary{
  &:hover, &:focus:active, &:focus-visible, &:active, &.active{
    img{
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(146deg) brightness(116%) contrast(100%);
    }
  }
}

.dropdown-item{
  &:hover, &:focus:active, &:focus-visible, &:active, &.active{
    img{
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(146deg) brightness(116%) contrast(100%);
    }
  }
}

@media(max-width: 1200.98px){
  .btn-top-bar{
    padding: 7px 12px;
    font-size: 13px;
    margin-left: 5px !important;
    img.me-1{
      width: 20px;
    }
  }
}

@media(max-width:415px){
  .btn-top-bar{
    img{
      display: none;
    }
  }
}

.navbar-fixed-top{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

.navbar-toggler:focus{
  box-shadow: none;
}

.error-div{
  background: #fff;
  text-align: center;
  padding: 24px 16px;
  border: $border-1;
  border-radius: 8px;
}

.tooltip-arrow::before {
  right: 0px !important; 
}

.min-vh-76{
  min-height: 76.5vh;
}

.min-vh-82{
  min-height: 82.7vh;
}

.min-vh-79{
  min-height: 79vh;
}

.bg-ECEDF9{
  background-color: #ECEDF9;
}

.text-555A7E{
  color: #555A7E;
}

sup {
  font-size: 12px !important;
  sup{
    top: 0 !important;
  }
}

.bg-FBF9F9{
  background: #FBF9F9;
}

.questions-choice{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p{
    padding-left: 0 !important;
    text-align: left !important;
  }
  p.text-justify{
    text-align: justify !important;
  }
}

.top-message{
  background: #FEF5EC;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: $border-1;
  text-align: center;
  color: #AF650E;
}

.top-message-success{
  background: #ECEDF9;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: $border-1;
  text-align: center;
  color: $primary;
}

.navbar-mobile{
  .navbar-brand{
    margin-left: 0px;
  }
}

.unlock-card {
  background-color: #ECEDF9;
  padding: 16px;
  border-radius: 12px;
}
.c-555A7E {
  color: #555A7E;
}
.text-semibold-16 {
  font-size: 16px;
  font-weight: 600;
}
.user-card {
  background: #FFFFFF;
  border: 1px solid #E6E6E7;
  padding: 16px;
  border-radius: 12px;
  border-radius: 12px;
}
.text-semibold-20 {
  font-size: 20px;
  font-weight: 600;
}
.user-progress-bar {
  background-color: #E6E6E7;
}
.user-progress-bar .progress-bar{
  background-color: #0AB237;
}
.c-4A4A4A {
  color: #4A4A4A;
}
.text-regular-14 {
  font-size: 14px;
  font-weight: 400;
}

.nodata-card {
  padding: 12px;
  border-radius: 12px;
  background: #FEF5EC;
}
.c-894F0B {
  color: #894F0B;
}
.text-medium-14 {
  font-size: 14px;
  font-weight: 500;
}
.text-caption {
  width: 19px;
text-align: center;
 font-size: 10px;
 font-weight: 500;
}
.progress-arrow {
  position: absolute;
  top: -28px;
}
.preview-card {
  padding: 12px;
  border-radius: 12px;
  background: #ECEDF9
}
@media only screen and (min-width: 576px) {
  .overlay-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
}

.hide_timer{
  font-size: 12px;
  color: $primary;
  cursor: pointer;
  text-align: center;
}

.visibility-hidden{
  display: none;
}

.font-10pt{
  font-size: 10pt;
}

.timer-help{
  position: absolute;
  top: 5px;
  right: 32px;
  background-color: #fff;
}

.form-check-label *{
  line-height: 1.4 !important;
}

.tinymce-font, .tinymce-font *{
  line-height: 1.4 !important;
  font-family: Helvetica, Arial, sans-serif !important;
}

.nav-link.active{
  font-weight: 600;
}

.min-w-51{
  min-width: 51px
}

.profile-card {
  background-color: #fff;
  max-width: 637px;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #E6E6E7;
}
.profile-list {
  border: 1px solid #E6E6E7;
  border-radius: 8px;
}
.profile-list .list-group-item.active {
  background-color: #ECEDF9;
  color: #00073D;
}
.profile-list .list-group-item {
  font-size: 14px;
}
.text-user-regular {
  font-size: 14px;
  color: #4A4A4A;
}
.text-user-medium {
  font-size: 14px;
  font-weight: 500;
  color: #141414;
}

.min-vh-65{
  min-height: calc(100vh - 66px);
}

.w-420{
  max-width: 420px;
}

.swal2-confirm{
  background: $primary !important;
}

.app-modal {
  .modal-dialog {
    max-width: 680px;
  }
 .left-colomun{
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  background-color: #FCF8F2;
 }
 .modal-header {
  position: absolute;
  right: 20px;
 }
}

.table>:not(caption)>*>*, table>:not(caption)>*>* {
  background-color: #fff !important;
}